<template>
  <v-container fluid pa-3 style="min-height: 100vh;">
    <v-row>
      <v-col cols="12" sm="8" order="1" order-sm="1">
        <v-row class="py-2 pa-0 mb-5 fill-width">
          <v-col cols="9" class="py-0">
            <h1 class="font-weight-light mb-0">Request Details</h1>
            <span class="overline"
              >Below is the information about this Request</span
            >
          </v-col>

          <v-col cols="3" class="pr-md-1">
            <v-btn @click="dialogDeliveryInfo = true" class="primary"
              >Contact Info</v-btn
            >
          </v-col>
        </v-row>

        <v-card class="py-2 pa-0 mb-5">
          <v-row wrap>
            <v-col grow class="py-1 elevation-2 driver-view">
              <GmapMap
                v-if="getSingleRequest.id"
                :center="{ lat: lat, lng: lng }"
                :zoom="10"
                map-type-id="terrain"
                ref="map"
                class="map"
                :options="{
                  zoomControl: true,
                  mapTypeControl: true,
                  scaleControl: true,
                  streetViewControl: true,
                  rotateControl: true,
                  fullscreenControl: true,
                  disableDefaultUI: true,
                }"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in getSingleRequest.positions"
                  :position="m"
                  :clickable="true"
                  :draggable="false"
                  @click="toggleInfoWindow(m, index)"
                  :icon="getPositionMarker(m)"
                />

                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick="infoWinOpen = false"
                >
                  <div v-html="infoContent"></div>
                </gmap-info-window>
              </GmapMap>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4" order="-1" order-sm="2">
        <v-card min-height="300" min-width="150" elevation="0">
          <v-row class="pa-3" v-if="getSingleRequest.author">
            <v-col offset="1" cols="10" class="background">
              <v-row>
                <v-col>
                  <div
                    class="text-center mb-2 title text-secundario font-weight-bold"
                  >
                    <h1>{{ getSingleRequest.vehicleType }}</h1>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <div
                    class="text-center mb-2 title text-secundario font-weight-bold"
                  >
                    Departure
                  </div>
                  <div
                    class="text-center mb-3 text-secundario font-weight-bold"
                  >
                    {{ `${getSingleRequest.pickupLocationLabel}` }}
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <div
                    class="text-center mb-2 title text-secundario font-weight-bold"
                  >
                    Destination
                  </div>
                  <div
                    class="text-center mb-3 text-secundario font-weight-bold"
                  >
                    {{ `${getSingleRequest.destinationLocationLabel}` }}
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <!------------------ PROFILE DATA ------------------>

                  <div
                    class="text-center mb-3 text-secundario font-weight-bold"
                  >
                    {{
                      getSingleRequest.ammount !== undefined
                        ? `UGX ${getSingleRequest.ammount}`
                        : `- - DELIVERY FEE - -`
                    }}
                  </div>
                  <div
                    class="text-center mb-3 text-secundario font-weight-bold"
                  >
                    {{
                      `${getSingleRequest.packageWeigh}KG (${getSingleRequest.packageSize})`
                    }}
                  </div>
                  <div
                    class="text-center mb-3 title text-secundario font-weight-bold"
                  >
                    <v-btn class="ml-auto" outlined color="primary">{{
                      getSingleRequest.status
                    }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeliveryInfo" width="500">
      <v-card>
        <v-card-title class="headline black" primary-title>
          Destination Contact Info
        </v-card-title>
        <v-card-text class="pa-5">
          <!--Request info here-->

          <v-row class="pa-0" v-if="getSingleRequest.author">
            <v-col offset="1" cols="10" class="background">
              <v-row>
                <v-col>
                  <div class="mb-2 title text-secundario font-weight-bold">
                    Contact Name
                  </div>
                  <div class="mb-3 text-secundario font-weight-bold">
                    <p>{{ `${getSingleRequest.recieverFullName}` }}</p>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <div class="mb-2 title text-secundario font-weight-bold">
                    Contact Phone
                  </div>
                  <div class="mb-3 text-secundario font-weight-bold">
                    <p>{{ `${getSingleRequest.recieverPhoneNumber}` }}</p>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <!------------------ PROFILE DATA ------------------>

                  <div class="mb-2 title text-secundario font-weight-bold">
                    Delivery Instructions
                  </div>
                  <div class="mb-3 text-secundario font-weight-bold">
                    <p>{{ `${getSingleRequest.deliveryInstructions}` }}</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="pa-5">
          <v-btn
            class="ml-auto"
            @click="dialogDeliveryInfo = false"
            outlined
            color="primary"
            >Cancel</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// VUEX
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import {
  mdiMapMarker,
  mdiWeb,
  mdiCalendar,
  mdiPencil,
  mdiPencilOutline,
  mdiCameraRetakeOutline,
} from "@mdi/js";

export default {
  data: () => ({
    lat: 0.315133,
    lng: 32.576353,
    valid: false,
    dialogDeliveryInfo: false,
    composeMessage: {},
    selectedGateWay: {},
    rules: {
      required: (value) => !!value || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
    svg: {
      location: mdiMapMarker,
      web: mdiWeb,
      calendar: mdiCalendar,
      pencil: mdiPencil,
      camera: mdiCameraRetakeOutline,
    },
    infoWindowPos: {},
    infoContent: "",
    currentMidx: null,
    infoWinOpen: false,
    infoOptions: {},
  }),

  beforeCreate() {
    // this.$store.dispatch("addDeliveryData");
    this.$store.dispatch("fetchSingleRequest", this.$route.query.requestID);
  },

  computed: {
    ...mapState(["selectedUser", "userSingleOrder"]),
    ...mapGetters(["getSingleRequest"]),
  },

  methods: {
    getPositionMarker: (marker) => {
      let markerData = {};
      if (marker.key === "deliveryGuyPosition") {
        markerData = { url: require("../../../assets/img/rider_up.png") };
      } else if (marker.key === "departurePosition") {
        markerData = { url: require("../../../assets/img/car_up.png") };
      } else if (marker.key === "destinationPosition") {
        markerData = {
          url: require("../../../assets/img/ic_maps_indicator_current_position.png"),
        };
      }
      return markerData;
    },

    displayTransactionContextMenu(item) {
      alert(item.id);
    },

    validate() {
      if (this.$refs.sendForm.validate()) {
        // send it
      }
    },

    loadOrderDetails(activeProduct) {
      this.$router.push({
        path: "/itemDetails",
        query: {
          purpose: activeProduct.productPurpose,
          itemID: activeProduct.id,
        },
      });
    },

    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker;
      this.infoContent = this.getInfoWindowContent(marker, idx);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent: function(marker) {
      if (marker.key === "deliveryGuyPosition") {
        return `<div class="card">
        <div class="card-content">
            <div class="media">
            <div class="media-content">
                <p class="title primary--text is-4">${
                  this.getSingleRequest.deliveryGuyProfile.username
                } (Delivery Guy)</p>
            </div>
            </div>
            <div class="content">
            <p class="primary--text">Time: ${this.getDateAndTimeString(
              this.getSingleRequest.deliveryGuyProfile.lastSeenPosition
                .lastUpdateTimeMillis
            )}
            <br>
            Phone: ${this.getSingleRequest.deliveryGuyProfile.userPhone}
            <br>
            Address: ${
              this.getSingleRequest.deliveryGuyProfile.lastSeenPosition
                .locationAddress
            }
            <br></p>
            </div>
        </div>
        </div>`;
      } else {
        return `<div class="card">
        <div class="card-content">
            <div class="media">
            <div class="media-content">
                <p class="title primary--text is-4">${
                  marker.key == "departurePosition"
                    ? "Departure"
                    : "Destination"
                }</p>
            </div>
            </div>
            <div class="content">
            <p class="primary--text">Time: ${this.getDateAndTimeString(
              this.getSingleRequest.timeRequestedTimeMillis
            )}
            <br>
            Instructions: ${this.getSingleRequest.deliveryInstructions}
            <br>${
              marker.key == "departurePosition"
                ? this.getSingleRequest.pickupLocationLabel
                : this.getSingleRequest.destinationLocationLabel
            }
            <br></p>
            </div>
        </div>
        </div>`;
      }
    },

    getDateAndTimeString(lastUpdateTimeMillis) {
      return new Date(lastUpdateTimeMillis).toString();
    },
  },
};
</script>

<style>
.driver-view {
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.top-bar {
  padding: 20px 0px;
  height: 120px;
  text-align: center;
}

.map {
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.bottom-bar {
  padding: 20px 0px;
  text-align: center;
}
</style>
